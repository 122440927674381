import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import { useEmblaCarousel } from 'embla-carousel/react';
import Button from 'components/formitems/button';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import classNames from 'classnames';
import { mqFrom } from 'styles/styles';
import { ImageFile } from 'r2d2';

export type ProductImagesCarouselProps = {
  images: ImageFile[];
  variantSku?: string;
  isListItem?: boolean;
};

const ProductImagesCarousel: React.FC<ProductImagesCarouselProps> = ({ images, variantSku, isListItem }) => {
  const [emblaRef, embla] = useEmblaCarousel({
    loop: false,
    containScroll: 'trimSnaps',
    align: 'start',
    inViewThreshold: 0,
  });

  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    selectedClass: '',
    axis: 'y',
    align: 'start',
    // inViewThreshold: 0,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  // const [scrollProgress, setScrollProgress] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    if (embla) {
      // Embla API is ready
      if (variantSku) {
        const skuImageIndex = images.findIndex((img) => img.sku?.toLowerCase() === variantSku.toLowerCase());
        embla.scrollTo(skuImageIndex);
      }
    }
  }, [embla, variantSku, images.map((item) => item._id).join('_')]); // eslint-disable-line

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    setSelectedIndex(embla.selectedScrollSnap());
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumbs, setSelectedIndex]);

  const onScroll = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    // const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    // setScrollProgress(progress * 100);
  }, [embla, setSelectedIndex]);

  const onThumbClick = useCallback(
    (index: number) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs],
  );

  useEffect(() => {
    if (!embla) return;
    onSelect();
    // onScroll();
    embla.on('select', onSelect);
    embla.on('scroll', onScroll);
  }, [embla, onSelect, onScroll]);

  const sizes = isListItem ? '(max-width: 600px) 100vw, 600px' : '(max-width: 600px) 100vw, 1000px';

  return (
    <StyledProductImagesCarousel>
      {!isListItem && images.length > 1 && (
        <div className="thumbs embla-viewport" ref={thumbViewportRef}>
          <div className="embla-container thumbs">
            {images.map((image, index) => (
              <div
                key={image._id}
                className={classNames('thumb', {
                  selected: index === selectedIndex,
                })}
                role="button"
                onClick={() => onThumbClick(index)}
                onKeyUp={() => onThumbClick(index)}
                tabIndex={0}
              >
                <Image
                  src={image.src}
                  width={image.width}
                  height={image.height}
                  layout="responsive"
                  alt={image.alt || ''}
                  sizes={sizes}
                />
                <div className="image-overlay" />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="zoomed embla-viewport" ref={emblaRef}>
        <div className="embla-container">
          {images.map((image) => (
            <div key={image._id} className="slide">
              <Image
                src={image.src}
                width={image.width}
                height={image.height}
                layout="responsive"
                alt={image.alt || ''}
                sizes={sizes}
                loading="eager"
              />
              <div className="image-overlay" />
            </div>
          ))}
        </div>
        {prevBtnEnabled && images.length > 1 && <Button className="prev" icon={FiChevronLeft} onClick={scrollPrev} />}
        {nextBtnEnabled && images.length > 1 && <Button className="next" icon={FiChevronRight} onClick={scrollNext} />}
        {images.length > 1 && (
          <div className="dots">
            {images.map((image, index) => (
              <div
                key={image._id}
                className={classNames('dot', {
                  selected: index === selectedIndex,
                })}
              />
            ))}
          </div>
        )}
      </div>
    </StyledProductImagesCarousel>
  );
};

const StyledProductImagesCarousel = styled.div`
  display: flex;

  .thumbs {
    width: 100px;
    display: none;

    ${mqFrom.desktop} {
      display: block;
    }

    .embla-container {
      flex-direction: column;
    }

    .thumb {
      margin: 0 1rem 1rem 0;
      position: relative;
      cursor: pointer;

      &.selected {
        border: 2px solid var(--primary-darker-background);
      }
    }
  }

  .zoomed {
    flex: 1 1 0;
    position: relative;
  }

  .embla-viewport {
    overflow: hidden;
  }

  .embla-container {
    display: flex;
  }

  .slide {
    position: relative;
    min-width: 100%;
  }

  button.prev,
  button.next {
    position: absolute;
    top: 50%;
    background-color: var(--primary-darker-background);
    transform: translateY(0);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  button.prev {
    left: 1rem;
  }
  button.next {
    right: 1rem;
    transition-delay: 100ms;
  }

  &:hover {
    button.prev,
    button.next {
      opacity: 1;
      transform: translateY(-50%);
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    ${mqFrom.desktop} {
      display: none;
    }

    .dot {
      margin: 0 0.2rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: var(--light-background);
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

      &.selected {
        transform: scale(1.1);
        margin: 0 0.5rem;
        background: var(--primary-darker-background);
      }
    }
  }

  .progress {
    position: relative;
    background-color: var(--light-background);
    top: -1rem;
    max-width: 270px;
    width: calc(100% - 40px);
    height: 4px;
    overflow: hidden;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .bar {
    position: absolute;
    background-color: var(--primary-darker-background);
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
  }
`;

export default ProductImagesCarousel;
