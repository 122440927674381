import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ProductItem from '../product/product-item';
import { getBoxStyles } from 'styles/styles';
import { PageItemProductsParamsProps } from 'r2d2';

export type ProductsProps = PageItemProductsParamsProps & {
  small?: boolean;
  rootLink?: boolean;
};

type StyledProps = {
  items: number;
};

export const Products: React.FC<ProductsProps> = ({ products, small, rootLink }) => {
  const { asPath } = useRouter();

  if (!products.length) {
    return null;
  }

  const pathWithoutQuery = asPath.split('?')[0];

  return (
    <StyledProducts className={`pageitem products ${small ? 'small' : 'regular'}`} items={products.length}>
      {products.map((item) => (
        <div key={item._id} className="column">
          <Link
            prefetch={false}
            href={`${['/', '/search'].includes(pathWithoutQuery) || rootLink ? '' : pathWithoutQuery}${item.url}`}
          >
            <ProductItem product={item} small={small} />
          </Link>
        </div>
      ))}
    </StyledProducts>
  );
};

const StyledProducts = styled.section<StyledProps>`
  padding: var(--side-padding);
  ${(props) => getBoxStyles(props.items)}

  .column {
    display: flex;
    flex-direction: column;
    > a {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      > div {
        flex: 1 1 100%;
      }
    }
  }
`;

export default Products;
