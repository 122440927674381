import { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntersection } from 'react-use';
import Image from 'next/legacy/image';
import { ContextProps, GlobalContext } from 'context/global-context';
import ImageSlides from './image-slides';
import { mqFrom } from 'styles/styles';
import classNames from 'classnames';
import ProductImagesCarousel from './product-images-carousel';
import { ListProduct } from 'r2d2';

type ProcuctItemProps = {
  product: ListProduct;
  small?: boolean;
};

const ProcuctItem: React.FC<ProcuctItemProps> = ({ product, small }) => {
  const {
    images: rawImages,
    // price,
    // priceFormatted,
    beforePriceFormatted,
    prices,
    banner,
    variants,
    inStock,
    artist,
    name,
    subText,
    // lockedPrices,
  } = product;

  // Fix så alle billeder der må vises på listevisning har samme ratio!
  const images = rawImages.filter((item) => {
    const diffHeight = rawImages[0].height > item.height ? rawImages[0].height - item.height : 0;

    const padding = (diffHeight / item.width) * 100;
    return padding === 0;
  });

  const { currentCurrency, device } = useContext<ContextProps>(GlobalContext);
  const [visible, setVisible] = useState<boolean>(false);
  const [hovered, setHovedered] = useState<boolean>(false);
  const intersectionRef = useRef(null);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  useEffect(() => {
    if (!visible && intersection?.intersectionRatio === 1) {
      setVisible(true);
    }
    if (visible && (intersection?.intersectionRatio ?? 0) < 1) {
      setVisible(false);
    }
  }, [intersection]); // eslint-disable-line

  let playSlides = false;
  if (['tablet', 'mobile'].includes(device) && visible) {
    playSlides = true;
  }
  if (['desktop', 'wide'].includes(device) && hovered) {
    playSlides = true;
  }

  return (
    <StyledProductItem
      onMouseEnter={() => {
        if (['desktop', 'wide'].includes(device)) {
          setHovedered(true);
        }
      }}
      onMouseLeave={() => {
        if (['desktop', 'wide'].includes(device)) {
          setHovedered(false);
        }
      }}
      className={classNames({
        visible,
        'sold-out': !inStock,
        small,
        regular: !small,
      })}
    >
      <div className="image-wrapper" ref={intersectionRef}>
        {banner && (
          <div className="banner">
            <div className="inner">{banner}</div>
          </div>
        )}
        <div className={`images ${images.length > 1 ? 'slides' : 'single'}`}>
          {images.length === 1 ? (
            <>
              <Image
                src={images[0].src}
                width={images[0].width}
                height={images[0].height}
                layout="responsive"
                sizes="500px"
                alt={images[0].alt || ''}
              />
              <div className="image-overlay" />
            </>
          ) : ['desktop', 'wide'].includes(device) ? (
            <>
              <ImageSlides images={images} play={playSlides} />
              <div className="image-overlay" />
            </>
          ) : (
            <ProductImagesCarousel images={images} isListItem />
          )}
        </div>
      </div>
      <div className="info">
        <div className="split">
          <div className="left">
            <h3 className="h4 name light skel">{name}</h3>
            <h4 className="subtext light skel">{subText}</h4>
            {artist?.name && <h4 className="artist light">{artist?.name}</h4>}
          </div>
          <div className="right">
            <div className="price h4 light skel">{prices[currentCurrency].priceFormatted}</div>
            {beforePriceFormatted && <div className="before h4 light skel">{beforePriceFormatted}</div>}
          </div>
        </div>
        {variants && variants.length > 0 && (
          <div className="variants">
            {variants.map((variant) => (
              <div key={variant._id} className={`attributes-block ${variant.type} attr-${variant.attributes.length}`}>
                {variant.attributes.map((attr) => (
                  <div
                    key={attr.sku}
                    className={`attribute skel ${attr.selected ? 'selected' : ''} ${
                      attr.inStock ? 'in-stock' : 'not-in-stock'
                    } ${attr.color ? 'has-color' : 'has-text'}`}
                    style={{ backgroundColor: attr.color || 'inherit' }}
                  >
                    {!attr.color && attr.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </StyledProductItem>
  );
};
const StyledProductItem = styled.div`
  --space-between-lines-margin: 0.3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--base-foreground);
  position: relative;
  transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.sold-out {
    > div:not(.banner) {
      opacity: 0.3;
    }
  }

  .banner {
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    overflow: hidden;
    pointer-events: none;

    .inner {
      text-align: center;
      background: var(--primary-background);
      color: var(--primary-foreground);
      transform: rotate(-45deg);
      text-transform: uppercase;
      font-weight: 800;
      padding: 0.5rem 1rem;
      position: absolute;
      width: 200px;
      top: 40px;
      left: -40px;
    }
  }

  .image-wrapper {
    border-bottom: 1px solid var(--border);
  }

  .images {
    position: relative;
  }

  .info {
    padding: 0.4rem 0 3rem 0;

    ${mqFrom.tablet} {
      min-height: 230px;
    }
  }

  .load-wrapper {
    transform: scale(0.5);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .variants {
    margin: 1rem 0;

    .attributes-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      // Fjern varianter der ikke er farver
      &.variant {
        font-size: 0.8rem;
      }

      // Fjern hvis der kun er en farve
      &.main.attr-1 {
        display: none;
      }

      .attribute {
        border: 1px solid var(--grey);
        margin: 0.2rem;
        border-radius: var(--button-border-radius);

        &.has-text {
          padding: 0.3rem 0.5rem;
        }

        &.has-color {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 1px solid #ddd;
        }
      }
    }
  }

  .name {
    margin: var(--space-between-lines-margin);
  }
  .subtext {
    margin: var(--space-between-lines-margin);
    color: var(--secondary-background);
  }
  .artist {
    margin: var(--space-between-lines-margin);
    color: var(--base-dimmed-foreground);
  }

  .split {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .before {
    color: var(--base-dimmed-foreground);
    text-decoration: line-through;
    margin: var(--space-between-lines-margin);
    white-space: nowrap;
    padding-left: 2rem;
    text-align: right;
    opacity: 0.5;
  }
  .price {
    margin: var(--space-between-lines-margin);
    white-space: nowrap;
    padding-left: 2rem;
    text-align: right;
  }

  .image-wrapper {
    overflow: hidden;
    position: relative;
    .single {
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  &:hover,
  &:focus {
    .image-wrapper {
      .single {
        transform: scale(1.05);
      }
    }
  }
`;

export default ProcuctItem;
