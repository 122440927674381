import React, { FC, useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { useTransition, animated, config } from 'react-spring';
import styled from 'styled-components';
import { useRecursiveTimeout } from 'utils/hooks';
import { ImageSlidesProps } from 'r2d2';

const delay = 2500;

type StyledProps = {
  slideCount: number;
  aspectRatioHeight: number;
};

export const ImageSlides: FC<ImageSlidesProps> = ({ images, play: run }) => {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(images[index], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  const { play, stop } = useRecursiveTimeout(() => setIndex((state) => (state + 1) % images.length), delay);

  useEffect(() => {
    if (run) {
      play();
    } else {
      setIndex(0);
      stop();
    }
    return () => {
      stop();
    };
  }, [run]); // eslint-disable-line

  if (images.length === 0) {
    return null;
  }

  const firstImage = images[0] as ImageType;

  // Brug første billedes højde i stedet
  const aspectRatioHeight = ((firstImage.height ?? 0) / (firstImage.width ?? 0)) * 100;

  return (
    <StyledImageSlides slideCount={images.length} aspectRatioHeight={aspectRatioHeight}>
      {transitions((props, item) => {
        const diffHeight =
          (firstImage.height ?? 0) > (item.height ?? 0) ? (firstImage.height ?? 0) - (item.height ?? 0) : 0;

        const padding = (diffHeight / (item.width ?? 0)) * 100;
        return (
          <animated.div className="slide" style={{ ...props, paddingTop: `${padding}%` }}>
            <Image
              src={item.src}
              width={item.width}
              height={item.height}
              layout="responsive"
              sizes="500px"
              alt={item.alt || ''}
            />
          </animated.div>
        );
      })}
      {run && (
        <div className="progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
          <span />
        </div>
      )}
    </StyledImageSlides>
  );
};

export const StyledImageSlides = styled.div<StyledProps>`
  /* Slider */
  position: relative;
  padding-top: ${(props) => props.aspectRatioHeight}%;
  background-blend-mode: multiply;

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* max-width: 500px; */
    /* border: 1px solid blue; */

    /* > div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex !important;
      justify-content: center;
      align-items: flex-end;

      border: 1px solid red;

      > div {
        border: 1px solid green;
      }
    } */
  }

  @keyframes progressBar {
    0% {
      width: 0%;
    }

    95% {
      width: 100%;
      opacity: 1;
    }

    100% {
      width: 100%;
      opacity: 0;
    }
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    height: 2px;
    background: var(--secondary-background);
    animation: progressBar ${({ slideCount }) => slideCount * delay + 0}ms infinite linear;
  }
`;

export default ImageSlides;
